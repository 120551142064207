import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import API from '../../services/API';
import InfinityLoader from "../../components/loaders/InfinityLoader";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function PositionsRegions() {
  const [ areasCorpo, setAreasCorpo ] = useState([]);
  const [ areasRegions, setAreasRegions ] = useState([]);
  const [ loadingInfo, setLoadingInfo ] = useState(true);
  const [ showMenu, setShowMenu ] = useState([0,1]);
  const { user, token } = useSelector((state) => state.authentication);

  useEffect(()=>{
    const options = { headers: { Authorization: token } };
    setLoadingInfo(true);
    API.get('/regions/areas', options)
    .then(({ data, status}) => {
      setLoadingInfo(false);
      if(status===200){
        setAreasCorpo(data.corpo.map( f =>{
          f.checked = false;
          return f;
        }));
        setAreasRegions(data.regions.map( f =>{
          f.checked = false;
          return f;
        }))
      }
    })
    .catch(error =>{
      setLoadingInfo(false);
    });
  }, []);

  const toggleShow = (item) => {
    let newShow = [...showMenu];
    let idx = newShow.indexOf(item);
    if(idx < 0){
      newShow.push(item);
    } else {
      newShow.splice(idx, 1);
    }
    setShowMenu(newShow);

  }

  let administradorRegional = user?.certificados?.filter(c => [28].includes(c.cat_permiso_id) && c.estatus == 1)?.length > 0;

  return (
    <div className="flex justify-center w-full">
      <div className="px-4 sm:px-6 lg:px-8 py-8 flex-1 max-w-6xl">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Descripciones de puesto</h1>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              {loadingInfo ? (
                <>
                  <InfinityLoader />
                </>
              ) : (
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full">
                    <thead className="bg-white">
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Área
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {!administradorRegional && <Fragment>
                        <tr className="border-t border-gray-200">
                          <th
                            className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                          >
                            Corporativo
                          </th>
                          <th
                            className="bg-gray-50 px-4 py-2 text-sm font-semibold text-gray-900 sm:px-6 text-right"
                          >
                            <button onClick={e => toggleShow(0)} className='ml-2 p-1 rounded-full bg-gray-200 text-gray-500'>{showMenu.includes(0) ? <ChevronUpIcon className='h-4 w-4' /> : <ChevronDownIcon className='h-4 w-4' />}</button>
                          </th>
                        </tr>
                        {showMenu.includes(0) && areasCorpo.map((person, personIdx) => (
                          <tr
                            key={`key-corpo-${person.area_id}`}
                            className={classNames(personIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                          >
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-700 sm:pl-6">
                              {person.area_nombre}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <Link to={`/t3b/admin/regions/CORPORATIVO/areas/${person.area_id}?area_nombre=${person.area_nombre}`} className="text-indigo-600 hover:text-indigo-900">
                                Puestos<span className="sr-only">, {person.area_nombre}</span>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </Fragment>}
                      <Fragment>
                        <tr className="border-t border-gray-200">
                          <th
                            className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                          >
                            Regionales
                          </th>
                          <th
                            className="bg-gray-50 px-4 py-2 text-sm font-semibold text-gray-900 sm:px-6 text-right"
                          >
                            <button onClick={e => toggleShow(1)} className='ml-2 p-1 rounded-full bg-gray-200 text-gray-500'>{showMenu.includes(1) ? <ChevronUpIcon className='h-4 w-4' /> : <ChevronDownIcon className='h-4 w-4' />}</button>
                          </th>
                        </tr>
                        {showMenu.includes(1) && areasRegions.map((person, personIdx) => (
                          <tr
                            key={`key-region-${person.area_id}`}
                            className={classNames(personIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                          >
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-700 sm:pl-6">
                              {person.area_nombre}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <Link to={`/t3b/admin/regions/REGION/areas/${person.area_id}?area_nombre=${person.area_nombre}`} className="text-indigo-600 hover:text-indigo-900">
                                Puestos<span className="sr-only">, {person.area_nombre}</span>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </Fragment>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
